

.overlay:hover .controls,
.overlay:focus-within .controls {
    opacity: 1;
}

.controls {
    opacity: 0.5;
    width: calc(100% - 10px);
    border-radius: 10px;
    position: absolute;
    bottom: 25px;
    margin-left: 5px;
    background-color: black;
    transition: 1s all;
    display: flex;
    height:30px;
  }

  .controls-active {
    opacity: 1;
  }
  
  .player:hover .controls,
  .player:focus-within .controls {
    opacity: 1;
  }

  .controls button {
    color: white;
    background: transparent;
    border:none;
  }

  .timer {
    line-height: 30px;
    font-size: 12px;
    color: white;
    flex: 5;
    position: relative;
  }
  
  .overlay.play .timer>div {
    transition: width 1s;
    transition-timing-function: linear;
  }


  .timer div {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.2);
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    z-index: 2;
    
  }
  
  .timer span {
    position: absolute;
    z-index: 3;
    left: 19px;
  }