.home-container {
    overflow-y:auto;
    overflow-x: hidden;
    height:100%;
}

.unicorn-container {
    position:relative;
}

.home-text {
    font-family: Helvetica Neue, Helvetica Neue, Helvetica, Arial, sans-serif;
}

@media (orientation: landscape) {
    .unicorn-container {
        aspect-ratio: 1;
        max-height:100%;
        max-width:50%;
        float:right;
    }

    .home-text {
        float:right;
        max-width:50%;
    }

    .home-text h1 {
        font-size:8vh;
    }
    
    .home-text p {
        font-size:3vh;
    }
}

@media (orientation: portrait) {
    .unicorn-container {
        /*width: calc(100vw - 70px);*/
        max-width:50%;
        aspect-ratio: 1;
        margin-left:25%;
    }

    .home-text h1 {
        font-size:6vw;
    }

    .home-text p {
        font-size:2vw;
    }
}

.home-text {
    padding:30px;
}

.unicorn-img {
    height:100%;
    display:block;
    position:relative;
    z-index:100;
}

.unicorn-slider {
    overflow:hidden;
    transform: rotate(23deg);
    position:absolute;
    height: 30%;
    width:30%; 
    top:0; 
    left:0; 
    margin-top: 37%;
    margin-left: 64.3%;
    z-index:98
}

.unicorn-slider div {
    position: relative;
    height:100%;
    margin: 0;
    left: 0;
    text-align: left;
    font-size: 0;
    /*animation: 30s slidy infinite;*/
}

.unicorn-slider div img {
    height:100%;
}